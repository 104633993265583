import React from "react";
import { FaWhatsapp, FaFacebook, FaInstagram } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";

const Links = () => {
  return (
    <div className="flex items-center justify-center flex-col text-black z-50 fixed right-1 bg-gray-200 p-2 rounded-lg gap-y-5 bottom-24">
      <a
        href="https://www.facebook.com/profile.php?id=100063948706893"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-3"
        aria-label="Visit our Facebook page"
      >
        <FaFacebook className="w-7 h-7 text-primary" />
      </a>
      <a
        href="https://www.instagram.com/everestradiator/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit our Instagram profile"
      >
        <FaInstagram className="w-7 h-7 text-primary" />
      </a>
      <a
        href="https://www.youtube.com/@EverestRadiator"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit our YouTube channel"
      >
        <IoLogoYoutube className="w-7 h-7 text-primary" />
      </a>
      <a
        href="https://wa.me/919384217772"
        target="_blank"
        rel="noopener noreferrer"
        className="mb-3"
        aria-label="Chat with us on WhatsApp"
      >
        <FaWhatsapp className="w-7 h-7 text-primary" />
      </a>
    </div>
  );
};

export default Links;
